import React, { useCallback, useEffect, useState } from 'react';
// import logo from '../assets/images/hired-logo.PNG';
// import map from '../assets/images/map.jpg';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchData } from '../config/Reausable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationDot } from '@fortawesome/free-solid-svg-icons';
import GoogleMapReact from 'google-map-react';
import Navbar from '../components/Navbar';
// import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function ViewJob () {

    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
      
    const navigate = useNavigate();
    const location = useLocation();
    const jobId = window.sessionStorage.getItem('jobId');
    const [jobTitle, setJobTitle] = useState("");
    const [packageStart, setPackageStart] = useState("");
    const [packageEnd, setPackageEnd] = useState("");
    const [payRateDurationType, setPayRateDurationType] = useState("");
    const [jobType, setJobType] = useState("");
    const [jobLocation, setJobLocation] = useState("");
    const [companyLogo, setCompanyLogo] = useState("");
    const [about, setAbout] = useState("");
    const [description, setDescription] = useState("");
    const [requirements, setRequirements] = useState("");
    const [skills, setSkills] = useState([]);
    const [latitude, setLatitude] = useState("");
    const [longitude, setLongitude] = useState("");
    const [video, setVideo] = useState([]);
    
    // Create a component for the marker
    const Marker = () => {
        return (
          <div style={{ color: 'red',
          borderRadius: '100%',
          transform: 'translate(-50%, -50%)'}}>
            <FontAwesomeIcon icon={faLocationDot} style={{ fontSize: '24px' }} />
          </div>
        );
      };

    // const settings = {
    //     infinite: false,
    //     slidesToShow: 3,
    //     slidesToScroll: 2,
    //     autoplay: false,
    //     speed: 1000,
    //     autoplaySpeed: 3000,
    //     arrows: false,
    //     responsive: [
    //       {
    //         breakpoint: 768,
    //         settings: {
    //           slidesToShow: 3,
    //         },
    //       },
    //       {
    //         breakpoint: 576,
    //         settings: {
    //           slidesToShow: 2,
    //         },
    //       },
    //     ],
    //   };
    const settings = {
        items: 1,
        loop: true,
        margin: 10,
        arrows: false, // Hide navigation arrows
        dots: false,
      };
    
        
      var images= video.filter((item) => item.mediaType !== "video");
      var videos= video.filter((item) => item.mediaType === "video");

    //   const carouselOptions = {
    //     items: 1,
    //     loop: true,
    //     margin: 10,
    //     nav: false, // Hide navigation arrows
    //     dots: false, // Hide navigation dots
        
    //   };
      
    const viewJobPost = useCallback(async () => {

        let data = {
            "jobId": jobId,
        };
        var response = await fetchData("viewJobPost",data);

        if(response.status === 200){
            setJobTitle(response.data[0].jobtitle);
            setPackageStart(response.data[0].packageStart);
            setPackageEnd(response.data[0].packageEnd);
            setPayRateDurationType(response.data[0].payRateDurationType);
            setJobType(response.data[0].type);
            setJobLocation(response.data[0].jobLocation);
            setCompanyLogo(response.data[0].companyLogo);
            setAbout(response.data[0].about);
            setDescription(response.data[0].description);
            setRequirements(response.data[0].requirements);
            setSkills(response.data[0].skills);
            setLatitude(response.data[0].latitude);
            setLongitude(response.data[0].longitude);
            setVideo(response.data[0].media);
        }
        else{
            console.log(response);
        }
    }, [jobId]);

    var userId = window.localStorage.getItem('userId');

    useEffect(() => {
        if (userId !== null && userId !== "" && userId !== undefined) {
            viewJobPost();
        } else {
        navigate('/');
        }
    }, [userId, viewJobPost, navigate]);
      
    const center = {
        lat: latitude !== "" ? parseFloat(latitude) : 0, // Default latitude if not set
        lng: longitude !== "" ? parseFloat(longitude) : 0, // Default longitude if not set
    };
      
    return(
    <>
    <div className='container'>
        <div className="form-wrap mt-5 p-4">
        <Navbar />
            <div className="row mt-1">
                <div className="col-12">
                    <div className="video-grid-slider">
                        {/* <Slider {...settings}>
                            {video.map((video,index) => (
                                <div key={index} className="video-slide p-1">
                                {video.mediaType === "video" ? (
                                    <>
                                        <video autoPlay muted loop style={{minHeight:"100px",height:"200px",objectFit:"cover",objectPosition:"50% 50%"}} controls>
                                        <source src={video.url} type="video/mp4" />
                                        Your browser does not support the video tag.
                                        </video>
                                    </>
                                    ) : (
                                        <img className='job_images' src={video.url} alt ="job images"/>
                                )}
                            </div>
                            ))}
                        </Slider> */}
                        <div className="row">
                            <div className="col-6 pr-0">
                            <Slider {...settings}>
                                {videos.map((video, index) => (
                                    <div key={index} className="video-slide p-1" style={{borderRadius: "10px"}}>
                                        
                                            <>
                                            <video autoPlay muted loop style={{minHeight:"100px",height:"200px",objectFit:"cover",objectPosition:"50% 50%"}} controls>
                                            <source src={video.url} type="video/mp4" />
                                            Your browser does not support the video tag.
                                            </video>
                                            {/* <div
                                                style={{
                                                position: 'absolute',
                                                bottom: '10px',
                                                right: '10px',
                                                color: 'white',
                                                fontSize: '14px',
                                                }}
                                            >
                                                {index + 1}/{videos.length}
                                            </div> */}
                                            </>
                                    </div>
                                ))}
                            </Slider>
                            </div>
                            <div className="col-6 pl-0">
                                <Slider {...settings}>
                                {images.map((image, index) => (
                                        <div key={index} className="video-slide p-1" style={{borderRadius: "10px"}}>
                                            
                                                <>
                                                <img className="job_images" src={image.url} alt={`job_image ${index + 1}`} />
                                                <div
                                                    style={{
                                                    position: 'absolute',
                                                    bottom: '10px',
                                                    right: '10px',
                                                    color: 'white',
                                                    fontSize: '14px',
                                                    }}
                                                >
                                                    {index + 1}/{images.length}
                                                </div>
                                                </>
                                        </div>
                                        ))}
                                </Slider>
                            </div>
                        </div>
                        


                    </div>
                </div>
            </div>
            <div className='row bg-white py-3 post_container'>
                <div className='col-md-9 col-7 d-flex flex-column justify-content-center'>
                        <h5>{jobTitle}</h5>
                        <p className='job_text my-2'>${packageStart} - {packageEnd} / {payRateDurationType}</p>
                        <p className='job_text my-2'>{jobType}</p>
                        <p className='job_text my-2'><span className="material-symbols-rounded location_icon2">location_on</span>
                        {jobLocation}
                        </p>
                </div>
                <div className='col-md-3 col-5'>
                    {/* <img src={map} className="map_image" alt='map img'/> */}
                    <div style={{width: '100%',height:'100%'}}>
                        <GoogleMapReact
                            bootstrapURLKeys={{ key: "AIzaSyAV2a8fa27mtsXyuQ-__N5xj3d_8TFa0Yc" }}
                            center={center}
                            zoom={11}
                        >
                            <Marker
                            lat={center.lat}
                            lng={center.lng}
                            />
                        </GoogleMapReact>
                    </div>    
                </div>
            </div>
            <div className='row pt-4 '>
                    <h6>Skills Needed</h6>
            </div>
            <div className='row bg-white py-2 key_container'>
                {skills.map((skill,index) => (
                    <div className='col-lg-4 col-6 my-2' key={index}>
                        <div className='info_container py-5 text-center '>
                            <span>{skill.name}</span>
                        </div>
                    </div>
                ))}
            </div>
            <div className='row py-3 '>
                <div className='col-md-2 col-4'>
                    <img src={companyLogo} className="profile_img" alt='Job post img'/>
                </div>
                <div className='col-md-10 col-8 d-flex flex-column '>
                    <Link className='job_link' to="../Profile">
                        <h5>About dawn</h5>
                        <p className='job_text my-4'>{about}</p>
                    </Link>
                </div>
            </div>
            <div className='row mt-4'>
                <div className='col-12'>
                    <h5>Job Description</h5>
                    <p>{description}</p>
                </div>
            </div>
            <div className='row mt-2'>
                <div className='col-12'>
                    <h5>Requirements</h5>
                    <p>{requirements}</p>
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12 d-flex justify-content-lg-end justify-content-center">
                    <Link className="btn editBtn" to="/Profile"><span className="material-symbols-rounded">arrow_back</span></Link>
                    <Link className="btn btn-primary nextBtn mx-lg-4 mx-1" to="../ViewApplicants" onClick={() => window.sessionStorage.setItem('jobId',jobId)}>View Applicants</Link>
                    <Link className="btn editBtn" to="../JobPost" state={{edit : 1, jobId : jobId}} onClick={()=>{sessionStorage.setItem('refreshJobPost',1)}}><span className="material-symbols-rounded">edit</span></Link>
                </div>
            </div>
        </div>
    </div>
    </>

    )
}
export default ViewJob;