import React, { useState, useEffect, useCallback } from 'react';
import companyImg from '../assets/images/company_img.png';
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation } from 'react-router-dom';
import { fetchData, fetchDataPrivate } from '../config/Reausable';
import AutoComplete from '../components/AddressAutoComplete';

function PersonalInfo() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
    const navigate = useNavigate();
    const location = useLocation();
    const edit = location.state ? location.state.edit : null;
    const [passwordType, setPasswordType] = useState("password");
    const [confirmPasswordType, setConfirmPasswordType] = useState("password");
    const [passwordInput, setPasswordInput] = useState("");
    const [confirmPasswordInput, setConfirmPasswordInput] = useState("");
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [salutationSelect, setSalutationSelect] = useState("default");
    const [genderSelect, setGenderSelect] = useState("default");
    const [email, setEmail] = useState(window.localStorage.getItem('email'));
    const [mobileNumber, setMobileNumber] = useState("");
    const [address, setAddress] = useState("");
    const [CityId, setCityId] = useState("default");
    

    const handlePasswordChange = (evnt) => {
        setPasswordInput(evnt.target.value);
    }

    const handleConfirmPasswordChange = (evnt) => {
        setConfirmPasswordInput(evnt.target.value);
    }

    const togglePassword = () => {
        setPasswordType(passwordType === "password" ? "text" : "password");
    }

    const toggleConfirmPassword = () => {
        setConfirmPasswordType(confirmPasswordType === "password" ? "text" : "password");
    }

    const handleAddressSelect = (selectedAddress) => {
        setAddress(selectedAddress);
    };

    const [majorCities, setMajorCities] = useState([]);
    const fetchMajorCity = async () =>{
        
        var response = await fetchData("majorCityList",JSON);

        if(response.status === 200){
            setMajorCities(response.data);
        }
        else {
            console.error('Error fetching major cities:', response);
        }
    }

    var userId = window.localStorage.getItem('userId');
    const submitPersonalInfo = async () =>{
        let data = {
            "userId": userId,
            "firstName": firstName,
            "lastName": lastName,
            "salutation": salutationSelect,
            "gender": genderSelect,
            "mobileNumber": mobileNumber,
            "email": email,
            "address": address,
            "majorCityId": CityId,
            "facebookLink": "",
            "instagramLink": "",
            "password": passwordInput,
            "confirmPassword": confirmPasswordInput
        }
        var response = await fetchDataPrivate("createProfileV2",data);
        if(response.status === 200){
            toast.success('Registration Successfully.', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
              });
            navigate('../CompanyInfo');
        }
        else{
            toast.error(response.message, {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
              });
            console.log(response);
        }
    }
    
    const editPersonalInfo = async () =>{
        let editDetails = {
            "userId": userId,
            "firstName": firstName,
            "lastName": lastName,
            "salutation": salutationSelect,
            "gender": genderSelect,
            "mobileNumber": mobileNumber,
            "email": email,
            "address": address,
            "majorCityId": CityId,
        }


        console.log(editDetails);
        var response = await fetchDataPrivate("createProfileV2",editDetails);
        if(response.status === 200){
            toast.success('Information Updated Successfully.', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
              });
            navigate('../Profile');
        }
        else{
            toast.error(response.message, {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
              });
            console.log(response);
        }


    }

    const fetchPerosnalInfo = useCallback(async () =>{
        let data2 = {
            "userId": userId
          };
        var response = await fetchDataPrivate("myProfile",data2);
    
        if(response.status === 200){
            setFirstName(response.data[0].firstName);
            setLastName(response.data[0].lastName);
            setSalutationSelect(response.data[0].salutation);
            setGenderSelect(response.data[0].gender);
            setMobileNumber(response.data[0].mobileNumber);
            setCityId(response.data[0].majorCity[0].cityId.toString());
            setAddress(response.data[0].address);
        }
        else{
            console.log(response);
        }

    },[userId]);

    useEffect(() => {
        fetchMajorCity();
        if(edit === 1){
            fetchPerosnalInfo();
        }
    }, [edit,fetchPerosnalInfo]);

  return (
    <div>
    <div className='container'>
        <div className="form-wrap mt-5">	
            <div className='company-form'>
                <div className='row'>
                    <div className='col-md-1'></div>
                    <div className='col-md-10 p-5'>
                        <img src={companyImg} className="banner-img" alt='company banner' style={{width:"100%"}} />
                    </div>
                    <div className='col-md-1'></div>
                </div>
                <div className='row'>
                    <div className='col text-center'>
                        <h3>Please enter your personal info</h3>
                    </div>
                </div>
                <div className="row mt-4">
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="firstName">Name</label>
                            <input type="text" name="firstName" id="firstName" placeholder="First Name" className="form-control" value={firstName} onChange={(e) => setFirstName(e.target.value)} required />
                        </div>
                    </div>
				    <div className="col-md-6">
                        <div className="form-group">
                            <br />
                            <input type="text" name="lastName" id="lastName" placeholder="Last Name" className="form-control mt-md-2" value={lastName} onChange={(e) => setLastName(e.target.value)} required />
                        </div>
                    </div>
                </div>
                <div className="row">
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="salutation">Salutation</label>
                            <select className='form-control' name='salutation' id='salutation' value={salutationSelect} onChange={(e) => setSalutationSelect(e.target.value)}>
                                <option disabled value="default">Select your salutation</option>
                                <option value="Mr.">Mr.</option>
                                <option value="Ms.">Ms.</option>
                                <option value="Mrs.">Mrs.</option>
                                <option value="Miss.">Miss.</option>
                            </select>
                        </div>
                    </div>
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="gender">Gender</label>
                            <select className='form-control' name='gender' id='gender' value={genderSelect} onChange={(e) => setGenderSelect(e.target.value)}>
                                <option disabled value="default">Select your gender</option>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                                <option value="Other">Other</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className='row'>
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="email">Email</label>
                            <input type="email" name="email" id="email" placeholder="Enter Email" className="form-control" value={email} onChange={(e) => setEmail(e.target.value)} required />
                        </div>
                    </div>
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="mobile">Mobile Number</label>
                            <input type="text" name="mobile" id="mobile" placeholder="Enter Mobile Number" className="form-control" value={mobileNumber} onChange={(e) => setMobileNumber(e.target.value)} required />
                        </div>
                    </div>
                </div>
                <div className="row">
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="majorCity">What is the closest major city?</label>
                            <select className='form-control' name='majorCity' id='majorCity' value={CityId} onChange={(e) => setCityId(e.target.value)}>
                                <option disabled value="default">Select your major City</option>
                                {majorCities.map((city, index) => (
                                    <option key={index} value={city.cityId}>{city.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="homeAddress">Address</label>
                            {/* <input type="text" name="homeAddress" id="homeAddress" placeholder="Home Address" className="form-control" required/> */}
                            <AutoComplete name="homeAddress" id="homeAddress" placeholder="Home Address" value={address} onAddressSelect={handleAddressSelect}/>
                        </div>
                    </div>
                </div>
                { 
                edit !== 1 
                ?
                    <div className="row">
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="password">Password: <span>(e.g.: Abc@12345)</span></label>
                                <div className="input-group">
                                    <input type={passwordType} value={passwordInput} name="password" id="password" placeholder="Password" className="form-control" onChange={handlePasswordChange} required />
                                    <div className="input-group-append">
                                        <span style={{color:'#00bcd9'}} className='input-group-text border-0' onClick={togglePassword}>{passwordType === "password" ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}</span>
                                    </div>
                                </div>
                                <span><i className="fa-solid fa-circle-info"></i> Password is minimum 8 characters including 1 Capital 1 Numeric 1 Special character</span>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="form-group">
                                <label htmlFor="confirmPassword">Confirm Password</label>
                                <div className="input-group">
                                    <input type={confirmPasswordType} value={confirmPasswordInput} name="confirmPassword" id="confirmPassword" placeholder="Confirm Password" className="form-control" onChange={handleConfirmPasswordChange} required />
                                    <div className="input-group-append">
                                        <span style={{color:'#00bcd9'}} className='input-group-text border-0' onClick={toggleConfirmPassword}>{confirmPasswordType === "password" ? <i className="fa-solid fa-eye-slash"></i> : <i className="fa-solid fa-eye"></i>}</span>
                                    </div>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    :
                    null
                }
                    <div className='row mt-4'>
                        <div className='col-md-4'>
                        { 
                        edit === 1 ? 
                            <Link className="btn editBtn mt-0" to="/Profile"><span className="material-symbols-rounded">arrow_back</span></Link>
                        : null 
                        }
                        </div>
                        <div className='col-md-4'>
                            <button type="submit" id="next" className="btn btn-primary btn-block nextBtn" onClick={()=> {

                                if (edit === 1)
                                {
                                    editPersonalInfo()
                                }
                                else
                                {
                                    submitPersonalInfo()
                                }
                            }}>{edit === 1 ? 'Update' : 'Next'}</button>
                        </div>
                        <div className='col-md-4'></div>
                    </div>
            </div>
        </div>
    </div>
    <ToastContainer />
    </div>
  )
}

export default PersonalInfo;
