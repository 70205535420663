import React, { useCallback, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { fetchDataPrivate } from '../config/Reausable';
import Navbar from '../components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as solidBookmark } from '@fortawesome/free-solid-svg-icons';
import { faBookmark as regularBookmark } from '@fortawesome/free-regular-svg-icons';

function ViewApplicants() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
      
    const navigate = useNavigate();
    const jobId = window.sessionStorage.getItem('jobId');
    const userId = window.localStorage.getItem('userId');
    const [video, setVideo] = useState([]);
    const [applicants, setApplicants] = useState([]);
    const [totalVideo, setTotalVideo] = useState("");
    const [totalApplicants, setTotalApplicants] = useState("");
    // const [jobApplicantsBtn, setJobApplicantsBtn] = useState(false);

    
    const settings = {
        infinite: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: false,
        speed: 1000,
        autoplaySpeed: 3000,
        arrows: false,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 3,
            },
          },
          {
            breakpoint: 576,
            settings: {
              slidesToShow: 2,
            },
          },
        ],
      };

    // view applicant list api call
    const ViewApplicants = useCallback(async () =>{
        let data = {
            "userId": userId,
            "jobId": jobId
        }
        const response = await fetchDataPrivate('applicantList', data);

        if(response.status === 200){
            setTotalVideo(response.data[0].reels.length);
            setApplicants(response.data[0].list);
            setVideo(response.data[0].reels);
            setTotalApplicants(response.data[0].list.length);

        } else{
            console.log(response);
        }

    },[userId,jobId]);

    // save job applicant api call here
    const saveJobApplicant = async(val) =>{
        // console.log("save job application id",val.jobapplicationId);
        // console.log("save user id",val.userId);
        let data = {
            "jobapplicationId": val.jobapplicationId,
            "userId": val.userId
        }
        const response = await fetchDataPrivate('saveJobApplicants', data);
        if(response.status === 200){
            
            ViewApplicants();
        }
        else{
            console.log(response);
        }
    }

    // remove job applicant api call here
    const removeJobApplicant = async(val) =>{
        
        let data = {
            "jobapplicationId": val.jobapplicationId,
            "userId": val.userId
        }
        const response = await fetchDataPrivate('saveJobApplicants', data);
        if(response.status === 200){
            
            ViewApplicants();
        }
        else{
            console.log(response);
        }
    }
    
    useEffect(() => {
        if (userId !== null && userId !== "" && userId !== undefined) {
            ViewApplicants();
        } else {
        navigate('/');
        }
    }, [userId, ViewApplicants, navigate]);

  return (
    <>
    <div className='container'>
        <div className="form-wrap mt-5 p-4">
        <Navbar />
            <div className="container mt-1">
                <h6 className='mt-4'>{totalVideo} Video Applicants</h6>    
                <div className="row">
                    <div className="col-12">
                        <div className="video-grid-slider">
                            <Slider {...settings}>
                                {video.map((video,index) => (
                                <div key={index} className="video-slide p-1">
                                    <video autoPlay muted loop>
                                    <source src={video.url} type="video/mp4" />
                                    Your browser does not support the video tag.
                                    </video>
                                    <div className="video-overlay">
                                        <h2 className="video-title px-2 py-1">{video.fullName}</h2>
                                        <p className="user-info px-2 py-1 pb-3">{video.bio}</p>
                                    </div>
                                </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
                <h6 className='mt-4'>{totalApplicants} Total Applicants</h6>    
                {applicants.map((list,index)=>(
                    <div className='row bg-white py-3 post_container my-3' key={index}>
                        
                        <div className='col-md-2 col-5'>
                            <img src={list.cacheImage} className="profile_img w-100 h-auto" alt='Job post img' />
                        </div>
                        <div className='col-md-8 col-5 d-flex flex-column justify-content-center'>
                                <h6>{list.fullName}</h6>
                                <p className='job_text my-1'>{list.bio}</p>
                                <p className='job_text my-1'>${list.minimumPayRate} - ${list.maximumPayRate} / {list.payRateDurationType}</p>
                        </div>
                        <div className='col-md-2 col-2 d-flex flex-column justify-content-between text-center'>
                        {list.isApplicantSaved == 0 && (
                            <button className='btn saveBtn shadow-none' onClick={() => saveJobApplicant(list)}><FontAwesomeIcon icon={regularBookmark} /></button>  
                        )}
                        {list.isApplicantSaved == 1 && ( 
                            <button className='btn saveBtn shadow-none' onClick={() => removeJobApplicant(list)}><FontAwesomeIcon icon={solidBookmark} /></button>  
                        )}
                            <span className='job_text'>{list.created}</span>
                        </div>
                    </div>
                ))}
                <div className="row mt-2">
                    <div className="col-12 d-flex justify-content-lg-end justify-content-center">
                        <Link className="btn editBtn" to="/ViewJob"><span className="material-symbols-rounded">arrow_back</span></Link>
                        <Link className="btn btn-primary nextBtn mx-lg-4 mx-1" to="../ContactApplicant" >Contact Applicants</Link>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </>
  )
}

export default ViewApplicants