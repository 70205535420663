// import firebase from 'firebase/compat/app';
import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBj7NhDbz8x__5OIxC0vI0HUY_Kii7gv94",
    authDomain: "dawnjobapp-b9983.firebaseapp.com",
    projectId: "dawnjobapp-b9983",
    storageBucket: "dawnjobapp-b9983.appspot.com",
    messagingSenderId: "858703722186",
    appId: "1:858703722186:web:0e89d9350870b9e1712633"
  };
  const firebaseApp = initializeApp(firebaseConfig);
const auth = getAuth(firebaseApp);
// const provider = new GoogleAuthProvider();
const provider = new GoogleAuthProvider();



export { auth, provider };