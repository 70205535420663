import React, { useRef, useEffect, useCallback, useState } from "react";

const AutoComplete = ({
  onAddressSelect,
  name,
  id,
  placeholder,
  latitude,
  longitude,
  value,
}) => {
  const autoCompleteRef = useRef(null);
  const inputRef = useRef(null);
  const [inputValue, setInputValue] = useState(value || "");

  const initAutocomplete = useCallback(() => {
    if (!window.google) {
      console.error("Google Maps API is not available.");
      return;
    }

    if (!window.google.maps || !window.google.maps.places) {
      console.error("Google Maps Places library is not available.");
      return;
    }

    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      {
        types: ["establishment"],
      }
    );

    autoCompleteRef.current.addListener("place_changed", () => {
      const place = autoCompleteRef.current.getPlace();

      if (place && place.formatted_address) {
        // Call the onAddressSelect callback with the formatted address
        onAddressSelect(place.formatted_address);

        // Update the input value with the selected address
        setInputValue(place.formatted_address);
        // Extract latitude and longitude from the place object
        const { lat, lng } = place.geometry.location;

        // Pass latitude and longitude to the parent component
        latitude(lat());
        longitude(lng());
      }
    });
  }, [onAddressSelect, latitude, longitude]);

  useEffect(() => {
    if (!window.google) {
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=AIzaSyAV2a8fa27mtsXyuQ-__N5xj3d_8TFa0Yc&libraries=places`;
      script.async = true;
      script.defer = true;
      script.onload = initAutocomplete;

      document.head.appendChild(script);

      return () => {
        // Cleanup script tag when unmounting
        document.head.removeChild(script);
      };
    } else {
      initAutocomplete();
    }
  }, [initAutocomplete]);

  useEffect(() => {
    setInputValue(value || "");
  }, [value]);

  return (
    <div>
      <input
        type="text"
        name={name}
        id={id}
        value={inputValue}
        placeholder={placeholder}
        className="form-control"
        ref={inputRef}
        onChange={(e) => setInputValue(e.target.value)}
        required
      />
    </div>
  );
};

export default AutoComplete;
