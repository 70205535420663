import React from 'react';
import { Routes, Route } from "react-router-dom";
import SignIn from "./pages/SignIn";
import PersonalInfo from './pages/PersonalInfo';
import CompanyInfo from './pages/CompanyInfo';
import CompanyProfile from './pages/CompanyProfile';
import JobPost from './pages/JobPost';
import ViewJob from './pages/ViewJob';
import ViewApplicants from './pages/ViewApplicants';
import SignUp from './pages/SignUp';
import Profile from './pages/Profile';
import ContactApplicant from './pages/ContactApplicant';
import "./assets/styles/main.css";
import './App.css';

function App() {
  return (
    <>
      <Routes>
        <Route path="/" exact element={<SignIn />} />
        <Route path="/SignUp" element={<SignUp />} />
        <Route path='/PersonalInfo' element={<PersonalInfo />} />
        <Route path='/CompanyInfo' element={<CompanyInfo />} />
        <Route path='/CompanyProfile' element={<CompanyProfile />} />
        <Route path='/Profile' element={<Profile />} />
        <Route path='/JobPost' element={<JobPost />} />
        <Route path='/ViewJob' element={<ViewJob />} />
        <Route path='/ViewApplicants' element={<ViewApplicants />} />
        <Route path='/ContactApplicant' element={<ContactApplicant />} />
      </Routes>
    </>
  );
}

export default App;

