import React from 'react';
import {Link, useNavigate} from 'react-router-dom';
// import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import logo from '../assets/images/hired-logo.PNG';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser,faFileArrowUp,faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';

function Navbar() {
    
    const navigate = useNavigate();

    const handleLogut = () =>{

        window.localStorage.removeItem('userId');
        window.localStorage.removeItem('email');
        window.localStorage.removeItem('token');
        window.sessionStorage.removeItem('jobId');
        navigate("../");
    }
  return (
    <>
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark" style={{borderRadius:"10px"}}>
        <div className="container-fluid">
            <Link to="../Profile" style={{width:"10%"}} className="m-0 rounded">
                <img className="navbar-brand" src={logo} alt="brand-logo" />
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse justify-content-end" id="navbarNav">
            <ul className="navbar-nav mx-2">
                <li className="nav-item">
                <Link className="nav-link my-0 d-flex" aria-current="page" to="../Profile"><FontAwesomeIcon icon={faCircleUser} style={{fontSize:"20px"}} className="mx-1"/> Profile</Link>
                </li>
                <li className="nav-item">
                <Link className="nav-link my-0 d-flex" to="../JobPost"><FontAwesomeIcon icon={faFileArrowUp} style={{fontSize:"20px"}} className="mx-1"/> JobPost</Link>
                </li>
            </ul>
            <div className="d-flex mx-2">
                <button className='btn btn-outline-primary' title='logout' style={{fontSize:"15px"}} onClick={handleLogut}><FontAwesomeIcon icon={faArrowRightFromBracket} /></button>
            </div>
            </div>
        </div>
    </nav>
    </>
  )
}

export default Navbar;
