import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchData, fetchDataPrivate } from '../config/Reausable';

function SignUp() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);
  const navigate = useNavigate();

  const [otpSent, setOtpSent] = useState(false);
  const [otp, setOtp] = useState(['', '', '', '']);
  const [verificationError, setVerificationError] = useState('');
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  
  const handleOtpChange = (e, index) => {
    const value = e.target.value;

    // Ensure only numbers are entered
    if (!isNaN(value) && value.length <= 1) {
      const updatedOtp = [...otp];
      updatedOtp[index] = value;
      setOtp(updatedOtp);

      // Move focus to the next input field if available
      if (e.target.value.length === 0 && index > 0) {
        inputRefs[index - 1].current.focus();
      } else if (e.target.value.length === 1 && index < inputRefs.length - 1) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const [email,setEmail] = useState('');
  const handleVerifyOTP = async () => {

    // Input validation
    if (!validateEmail(email)) {
      toast.error('Invalid Email Address', {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      return;
    }

    let data = {
      "appVersion": "appVersion",
      "device": "deviceName",
      "email": email,
      "os": "android",
      "osVersion": "1"
    };
    
    try {
      const response = await fetchData('registration', data);
      
      if (response.status === 200) {
        toast.success('OTP Generated Successfully.', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
        setOtpSent(true);
        window.localStorage.setItem('userId', response.data[0].userId);
        window.localStorage.setItem('token', response.data[0].token);
        window.localStorage.setItem('email',email);
      } else {
        toast.error(response.message, {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
        });
      }
    } catch (error) {
      
      console.error('API request failed:', error);
      // Handle other error cases here
    }
  };
  
  var userId = window.localStorage.getItem('userId');
  const validateEmail = (email) => {
    // Use a proper email validation regex or library
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleOtpSubmit = async () => {
    let data = {
      "userId": userId,
      "otp": otp.join('')
    };
    const response = await fetchData('verifyOTP', data);
    
    if (response.status === 200) {
      const accountType = async () => {
        let data2 ={
          "userId" : userId,
          "type" : "employer"
        }
        const response2 = await fetchDataPrivate('setAccountType', data2);

        if(response2.status === 200){
          navigate('/PersonalInfo');
        } 
        else {
          console.log(response2);
        }
      }
      accountType();
      
    } else {
      // OTP is incorrect, display an error message
      setVerificationError('Incorrect OTP. Please try again.');
    }
  };

  return (
    <div className="main-container">
      <div className="content" id="content">
        <div className="form-container sign-in-container d-flex justify-content-center pl-5 pr-5 h-80 align-items-center flex-column">
            {!otpSent ? (
              <>
                <h1 className="my-5">Sign Up</h1>
                <input
                  type="email"
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <button id="signUp" className="ghost my-4" onClick={handleVerifyOTP}>
                  Verify OTP
                </button>
                <button className="ghost web_hide" onClick={() => navigate('/')}>
                  Sign In
                </button>
              </>
            ) : (
              
                <>
                <h6 className='text-white' style={{fontWeight:'900'}}>OTP sent to your email. Check your inbox!</h6>
                <div className="otp-input d-flex align-items-center justify-content-between">
                {otp.map((value, index) => (
                  <input
                    key={index}
                    type="text"
                    maxLength="1"
                    className="rounded mx-2 p-2"
                    style={{ maxWidth: '60px' }}
                    value={value}
                    onChange={(e) => handleOtpChange(e, index)}
                    ref={inputRefs[index]}
                  />
                ))}
                </div>
                <button className="ghost my-4" onClick={handleOtpSubmit}>
                  Submit OTP
                </button>
                {verificationError && <p className="error text-white">{verificationError}</p>}

                </>
                
                )}
                <ToastContainer />
            
        </div>
        <div className="overlay-container">
          <div className="overlay text-center">
            <div className="overlay-panel overlay-left overlay-form-container">
              <h1 className="my-5">Sign In</h1>
              <button className="ghost" onClick={() => navigate('/')}>
                Sign In
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SignUp;
