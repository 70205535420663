import React, { useEffect, useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import { auth, provider } from "./Firebase";
import {signInWithPopup} from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { fetchData } from '../config/Reausable';


function SignIn() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  const signInWithGoogleAndPopup = () => {

  provider.setCustomParameters({ prompt: 'select_account' });
    
  signInWithPopup(auth, provider)
    .then((result) => {
      // console.log("Successfully signed in with Google:", result);
      var userEmail = result.user.email;
      var federatedId = result.user.reloadUserInfo.providerUserInfo[0].federatedId;
      window.localStorage.setItem('email',userEmail);
      const socialMediaLogin = async () =>{
        let data = {
          "appVersion": "1.0",
          "device": "iPhone 14",
          "email": userEmail,
          "id": federatedId,
          "loginType": "googleId",
          "os": "ios",
          "osVersion": "16.2"
        };
        var response = await fetchData("socialMediaLogin",data);

        if(response.status === 200){
          if(response.data[0].type === "EMPLOYER")
          {
            navigate('../Profile');
            window.localStorage.setItem('token',response.data[0].token);
            window.localStorage.setItem('userId',response.data[0].userId);

          } else {
              toast.error('Only Company account can be login.', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
              });
          }
        }
        else{
          console.log(response);
        }

      }
      socialMediaLogin();
    })
    .catch((error) => {
      console.log("Sign-in error:", error);
    });

  };
  const navigate = useNavigate();

  const handleSignInClick = () => {
    const content = document.getElementById('content');
    content.classList.add('right-panel-active');
  };
  const handleBackClick = () => {
    const content = document.getElementById('content');
    content.classList.remove('right-panel-active');
  };

  const [email,setEmail] = useState('');
  const [password,setPassword] = useState('');

  const signInApi = async () => {
    let data2 = {"email": email,
    "password": password,
    "appVersion": "appVersion",
    "device": "deviceName",
    "os": "android",
    "osVersion": "1"
    };
    
    var response = await fetchData("loginWithPassword",data2)
    if(response.status === 200){
        if(response.data[0].accountType === "EMPLOYER")
        {
          toast.success('Login Successfully.', {
            position: 'top-right',
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: 'dark',
          });
          navigate('../Profile');
          window.localStorage.setItem('userId',response.data[0].userId);
          window.localStorage.setItem('email',email);
          window.localStorage.setItem('token',response.data[0].token);
        }
        else
        {
            toast.error('Only Employee Can Login.', {
              position: 'top-right',
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: 'dark',
            });
        }
    }
    else{
      toast.error(response.message, {
        position: 'top-right',
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: 'dark',
      });
      console.log("Error",response);
    }
  }

  return (
    <div className='main-container'>
      <div className="content" id="content">
        <div className="form-container sign-in-container">
            <button type="button" className="btn btn-google my-4" onClick={signInWithGoogleAndPopup}>
              SIGN IN WITH GOOGLE
            </button>
            <button type="button" className="btn btn-email" onClick={handleSignInClick}>
              SIGN IN WITH EMAIL
            </button>
        <div className='back-container'>
          <button className="ghost my-4 web_hide" onClick={()=>navigate("./SignUp")}>
                Sign Up
          </button>
        </div>
        </div>
        <div className="form-container sign-up-container">
            <h1 className='web_hide my-4'>Sign In</h1>
            <input type="email" value={email} placeholder="Email"  onChange={(e) => setEmail(e.target.value)}/>
            <input type="password" value={password} placeholder="Password"  onChange={(e) => setPassword(e.target.value)} />
            <Link to="" className='text-white font-bold'>Forgot password?</Link>
            <button className='ghost my-4' onClick={() => {signInApi()}}>Login</button>
        <div className='back-container'>
            <button className='ghost web_hide w-20' onClick={handleBackClick}>Back</button>
        </div>
        </div>
        <div className="overlay-container">
          <div className="overlay text-center">
            <div className="overlay-panel overlay-left">
              <h1>Hello, Friend!</h1>
              <p className="my-5">
              Welcome to Dawn! We are thrilled to have you join our community. Kindly provide us with your personal information, so we can tailor your experience and embark on this exciting journey together.
              </p>
              <button className="ghost" onClick={()=>navigate("./SignUp")}>
                Sign Up
              </button>
            </div>
            <div className="overlay-panel overlay-right">
                  <h1>Sign In</h1>
                  <p className='my-5'>Please enter your personal info</p>
                  <button className='ghost' onClick={handleBackClick}>Back</button>
              </div>
          </div>
          
        </div>
        <ToastContainer />
      </div>
    </div>
  );
}

export default SignIn;
