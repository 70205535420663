import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AutoComplete2 from '../components/AddressAutoComplete2';
import { fetchData, fetchDataPrivate, fetchFormData } from '../config/Reausable';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import Navbar from '../components/Navbar';

function JobPost() {
    useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

    if(sessionStorage.getItem('refreshJobPost')){
        window.location.reload();
        sessionStorage.removeItem('refreshJobPost');
    }
    const navigate = useNavigate();
    const location = useLocation();
    const edit = location.state ? location.state.edit : null;
    const jobId = location.state ? location.state.jobId : null;
      
    const [jobTitle, setJobTitle] = useState("");
    const [address, setAddress] = useState("");
    const [longitude, setLongitude] = useState(null);
    const [latitude, setLatitude] = useState(null);
    const [categoryId, setCategoryId] = useState("default");
    const [jobStatus, setJobStatus] = useState("default");
    const [skillId, setSkillId] = useState([]);
    const [jobLocationType, setJobLocationType] = useState("ONSITE");
    const [imageVideo, setImageVideo] = useState([]);
    const [jobCategory, setJobCategory] = useState([]);
    const [skillList, setSkillList] = useState([]);
    const [imageUrl, setImageUrl] = useState([]);
    const [jobType, setJobType] = useState("");
    const [packageStart, setPackageStart] = useState("");
    const [packageEnd, setPackageEnd] = useState("");
    const [payRateDurationType, setPayRateDurationType] = useState("default");
    const [about, setAbout] = useState("");
    const [description, setDescription] = useState("");
    const [requirements, setRequirements] = useState("");
    var userId = window.localStorage.getItem('userId');

    const fileInputRef = useRef(null);
    const handleUploadBoxClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleLabelClick = (e) => {
        e.stopPropagation();
    };

    const MAX_LENGTH = 10;
    // const handleImageChange = (e) => {
    //     const files = Array.from(e.target.files);
        
    //     if (files.length + imageVideo.length + imageUrl.length > MAX_LENGTH) {
    //         console.log(`Cannot upload more than ${MAX_LENGTH} files.`);
    //         toast.error(`Cannot upload more than ${MAX_LENGTH} files.`, {
    //             position: 'top-right',
    //             autoClose: 2000,
    //             hideProgressBar: false,
    //             closeOnClick: true,
    //             pauseOnHover: true,
    //             draggable: true,
    //             progress: undefined,
    //             theme: 'dark',
    //         });
    //     } else {
    //         setImageVideo(prevFiles => [...prevFiles, ...files]);
    //     }
    // };
    
    const handleImageChange = (e) => {

        if (e && e.target.files.length > 0) {

            const files = Array.from(e.target.files);
            const videoFiles = files.filter(file => file.type.startsWith('video/'));
            const existingVideos = imageUrl.filter(file => file.mediaType === 'video');
            const previousvideo = imageVideo.filter(item => item.type.startsWith('video/'))

            if (files.length + imageVideo.length + imageUrl.length > MAX_LENGTH) {
                console.log(`Cannot upload more than ${MAX_LENGTH} files.`);
                toast.error(`Cannot upload more than ${MAX_LENGTH} files.`, {
                    position: 'top-right',
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: 'dark',
                });
            } else {
                
                if (videoFiles.length + existingVideos.length === 0) {
                    setImageVideo(prevFiles => [...prevFiles, ...files]);
    
                } else if (videoFiles.length + existingVideos.length + previousvideo.length > 1) {
                    toast.error('You can upload only one video.', {
                        position: 'top-right',
                        autoClose: 2000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true,
                        progress: undefined,
                        theme: 'dark',
                    });
                } else{
                    setImageVideo(prevFiles => [...prevFiles, ...files]);
                }
            }
        }
    };
    
    const handleRemove1 = async (index1) => {
        // const updatedMediaFiles = [...imageVideo];
        // updatedMediaFiles.splice(index, 1);
        // setImageVideo(updatedMediaFiles);

        let tmparrimg = imageUrl.filter((item,index) => index != index1)

        setImageUrl(tmparrimg);

        let JobMedia = {
            
            "jobId": jobId,
            "jobmediaId":imageUrl[index1].jobmediaId
          };
        var response = await fetchDataPrivate("deleteJobMedia",JobMedia);

        if(response.status === 200){
            setImageUrl(tmparrimg);

        }
        else {
            console.error('Error fetching Job Category:', response);
        }

    };
    const handleRemove2 = async (index1) => {
        

        let tmparrimg = imageVideo.filter((item,index) => index != index1)

        setImageVideo(tmparrimg);
    };

    const onlyNumber1 = (e) => {
        const result = e.target.value.replace(/\D/g, '');
        setPackageStart(result);
    };

    const onlyNumber2 = (e) => {
        const result = e.target.value.replace(/\D/g, '');
        setPackageEnd(result);
    };

    const fetchJobCategory = useCallback(async () =>{
        
        var response = await fetchData("categoryList",JSON);

        if(response.status === 200){
            setJobCategory(response.data);
        }
        else {
            console.error('Error fetching Job Category:', response);
        }
    }, []);

    const fetchSkillList = useCallback(async () =>{

        let data = {
            "keyword": ""
          };
        
        var response = await fetchData("skillList",data);

        if(response.status === 200){
            setSkillList(response.data);
        }
        else {
            console.error('Error fetching Skill:', response);
        }
    }, []);

    useEffect(() => {
        if (userId !== null && userId !== "" && userId !== undefined) {
            fetchJobCategory();
            fetchSkillList();
        } else {
        navigate('/');
        }
    }, [userId, fetchJobCategory, fetchSkillList, navigate]);

    const PostJob = async () =>{
        setLoading(true);
        let data = new FormData();
        data.append('userId',userId);
        data.append('jobtitle',jobTitle);
        data.append('skill',skillId);
        data.append('packageStart',packageStart);
        data.append('packageEnd',packageEnd);
        data.append('about',about);
        data.append('description',description);
        data.append('requirements',requirements);
        imageVideo.forEach((imagePath) => {
            data.append('media', imagePath);
        });
        data.append('type',jobType);
        data.append('jobLocationType',jobLocationType);
        data.append('jobLocation',address);
        data.append('payRateDurationType',payRateDurationType);
        data.append('latitude',latitude);
        data.append('longitude',longitude);
        data.append('categoryId',categoryId);
        data.append('isActive',jobStatus);

        var response = await fetchFormData("postJob",data);
        if(response.status === 200){
            toast.success('Job Post Successfully.', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
            navigate('../Profile');

            console.log(response.data);
        }
        else{
            toast.error(response.message, {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
            console.log(response);
        }
    } 
    var optionList = [];
    skillList.forEach((skill) => {
        optionList.push({
            value: skill.data.value,
            label: skill.data.label
        });
    });
    
    const fetchJobPost = useCallback(async() =>{
        let JobDetails = {
            "userId": userId,
            "jobId": jobId
          };
        var response = await fetchDataPrivate("viewJobPost",JobDetails);

        if (response.status === 200) {

            if (response.data.length != 0){

                setJobTitle(response.data[0].jobtitle);
                // let lastIndex = response.data[0].media.length - 1;

                const updatedMediaFiles = response.data[0].media.map((item,index) => {
            
                    return{
                        url: item.url,
                        type: item.type,
                        mediaType: item.mediaType,
                        jobmediaId: item.jobmediaId
                    }
                });

                setImageUrl(updatedMediaFiles);
                setJobType(response.data[0].type);
                const selectedSkills = response.data[0].skills.map(skill => skill.skillId);
                setSkillId(selectedSkills);
                setCategoryId(response.data[0].category[0].categoryId);
                setJobStatus(response.data[0].isActive);
                setAddress(response.data[0].jobLocation);
                setPackageStart(response.data[0].packageStart);
                setPackageEnd(response.data[0].packageEnd);
                setPayRateDurationType(response.data[0].payRateDurationType);
                setAbout(response.data[0].about);
                setDescription(response.data[0].description);
                setRequirements(response.data[0].requirements);
                setLatitude(response.data[0].latitude);
                setLongitude(response.data[0].longitude);
                
            }

          }
          else{
            console.log();
          }
          

    },[userId,jobId]);

    useEffect(() => { 
        if(edit === 1){
            fetchJobPost();
        }
    },[edit,fetchJobPost]);

    const [loading,setLoading] = useState(false);
    const editJobPost = async () =>{
        setLoading(true);
        var formdata = new FormData();

        formdata.append("jobId", jobId)
        formdata.append("userId", userId);
        formdata.append("jobtitle", jobTitle);
        formdata.append("skill",String(skillId));
        formdata.append("description", description);
        formdata.append("about", about);
        formdata.append("requirements", requirements);
        imageVideo.forEach((imagePath) => {
            formdata.append('media', imagePath);
        });
        formdata.append("packageStart", packageStart);
        formdata.append("packageEnd", packageEnd);
        formdata.append("type", jobType);
        formdata.append("jobLocation", address)
        formdata.append('latitude', latitude)
        formdata.append('longitude', longitude)
        formdata.append('payRateDurationType', payRateDurationType)
        formdata.append('categoryId', categoryId)
        formdata.append('isActive', jobStatus)

        var response = await fetchFormData("editJobPost", formdata)
        
        if(response.status === 200){
            toast.success('Job Details Updated.', {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
            navigate("../ViewJob",{state : {jobId : jobId}});
        }
        else{
            toast.error(response.message, {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: 'dark',
            });
            
        }
    }
   
  return (
    <>
    <div className='container'>
        <div className="form-wrap mt-5 p-4">	
            <Navbar />
            <div className='company-form mt-1'>
                <div className="row">
                    <div className='col-12'>
                        <h2 className='text-center'>Posting a Job</h2>
                    </div>
                </div>
                <div className="row mt-4">
				    <div className="col-12">
                        <div className="form-group">
                            <label htmlFor="jobTitle">Job Title</label>
                            <input type="text" name="jobTitle" id="jobTitle" placeholder="Title" className="form-control" value={jobTitle} onChange={(e) => setJobTitle(e.target.value)} required />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className='col'>
                        <div className='image-container'>
                            <input
                            type="file"
                            id="coverPhotos"
                            className="upload-image"
                            accept="video/*,image/*"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleImageChange}
                            multiple 
                            />
                            <label className="upload-preview">
                                <div className="upload-box" onClick={handleUploadBoxClick}>
                                    <label className="upload-box-label" htmlFor="coverPhotos" onClick={handleLabelClick}>+</label>
                                    <span className="upload-box-text text-center">Add Video Or Pics</span>
                                </div>
                            </label>
                        </div>   
                    </div>
                </div>
                
                <div className="row mt-3">
                {imageUrl && Array.isArray(imageUrl) && imageUrl.length > 0 && (

                    imageUrl.map((imageList, index) => (
                    <div className="col-lg-3 col-6" key={index}>
                        <div className="mt-3" style={{ position: "relative", maxWidth: "fit-content" }}>
                        {imageList.mediaType === "video" ? (
                            <video autoPlay muted loop>
                            <source src={imageList.url} type="video/mp4" />
                            Your browser does not support the video tag.
                            </video>
                        ) : (
                            <img src={imageList.url} alt={`Media ${index}`} width="100%" />
                        )}
                        </div>
                        <button
                        className="btn btn-danger p-1 px-2 rounded-circle"
                        style={{
                            position: "absolute",
                            right: "5%",
                            top: "5%",
                            fontSize: '12px',
                          }}
                        onClick={() => handleRemove1(index)}
                        >
                        <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </div>
                    ))
                )}
                {imageVideo && Array.isArray(imageVideo) && imageVideo.length > 0 && (
                
                    imageVideo.map((imageList, index) => (
                    <div className="col-lg-3 col-6" key={index}>
                        <div className="mt-3" style={{ position: "relative", maxWidth: "fit-content" }}>
                        {imageList.type && imageList.type.startsWith("video/") ? (
                            <video autoPlay muted loop>
                            <source src={URL.createObjectURL(imageList)} type={imageList.type} />
                            Your browser does not support the video tag.
                            </video>
                        ) : (
                            <img src={URL.createObjectURL(imageList)} alt={`Media ${index}`} width="100%" />
                        )}
                        </div>
                        <button
                        className="btn btn-danger p-1 px-2 rounded-circle"
                        style={{
                            position: "absolute",
                            right: "5%",
                            top: "5%",
                            fontSize: '12px',
                          }}
                        onClick={() => handleRemove2(index)}
                        >
                        <FontAwesomeIcon icon={faXmark} />
                        </button>
                    </div>
                    ))
                    )}
                </div>

                <div className="row mt-4">
                    <div className='col-md-6 d-flex justify-content-between'>
                        <div className="form-check">
                            <input className="form-check-input jobRadio" type="radio" name="jobTime" id="partTime" value="PartTime" onChange={(e) => setJobType(e.target.value)} checked={jobType === 'PartTime'}/>
                            <label className="form-check-label pt-1 pl-2" htmlFor="partTime">
                                Part Time
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input jobRadio" type="radio" name="jobTime" id="fullTime" value="FullTime" onChange={(e) => setJobType(e.target.value)} checked={jobType === 'FullTime'}/>
                            <label className="form-check-label pt-1 pl-2" htmlFor="fullTime">
                                Full Time
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input jobRadio" type="radio" name="jobTime" id="project" value="Project" onChange={(e) => setJobType(e.target.value)} checked={jobType === 'Project'}/>
                            <label className="form-check-label pt-1 pl-2" htmlFor="project">
                                Project
                            </label>
                        </div>
                    </div>
                </div>
                <div className="row mt-4">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="skills">Skills <span>(Pick up to 10 skills required for your job)</span></label>
                            {/* <select className='form-control' name='skills' id='skills' value={skillId} onChange={(e) => setSkillId(e.target.value)}>
                                
                            </select> */}
                            {/* <Select className='' name='skills' id='skills' value={skillId.map(value => ({ value, label: optionList.find(option => option.value === value).label }))} options={optionList} onChange={(value) => setSkillId(value.map(option => option.value))} isSearchable={true} isMulti /> */}
                            <Select
                            name="skills"
                            id="skills"
                            value={optionList.filter(option => skillId.includes(option.value))}
                            options={optionList}
                            onChange={selectedOptions => {
                                const selectedIds = selectedOptions.map(option => option.value);

                                // Limit the selected options to 10
                                if (selectedIds.length <= 10) {
                                    setSkillId(selectedIds);
                                } else {
                                    // If more than 10 options are selected, only keep the first 10
                                    setSkillId(selectedIds.slice(0, 10));
                                }
                            }}
                            isSearchable={true}
                            isMulti
                        />


                                
                        </div>
                    </div>
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="jobCategory">Job Category</label>
                            <select className='form-control' name='jobCategory' id='jobCategory' value={categoryId} onChange={(e) => setCategoryId(e.target.value)}>
                                <option disabled value="default">Job Category</option>
                                {jobCategory.map((category,index) => (
                                    <option key={index} value={category.categoryId}>{category.categoryName}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="jobStatus">Job Status</label>
                            <select className='form-control' name='jobStatus' id='jobStatus' value={jobStatus} onChange={(e) => setJobStatus(e.target.value)}>
                                <option disabled value="default">Job Status</option>
                                <option value="0">deactive</option>
                                <option value="1">active</option>
                            </select>
                        </div>
                    </div>
				    <div className="col-md-6">
                        <div className="form-group">
                            <div className='d-flex justify-content-between'>
                                <label htmlFor="jobLocation">Job Location:</label>
                                <div className="form-check">
                                    <input className="form-check-input mt-2" type="checkbox" id="jobLocationChecked" value="REMOTE" onChange={(e) => setJobLocationType(e.target.checked ? "REMOTE" : "ONSITE")} checked={jobLocationType === "REMOTE"}  />
                                    <span className="form-check-label" htmlFor="jobLocationChecked">
                                        This job is fully remote
                                    </span>
                                </div>
                            </div>
                            <AutoComplete2 name="jobLocation" id="jobLocation" placeholder="Enter Job Location" value={address} onAddressSelect={(address) => setAddress(address)} longitude={(lng) => setLongitude(lng)} latitude = {(lat) => setLatitude(lat) } />
                        </div>
                    </div>
                </div>
                <div className='row'>
				    <div className="col-md-4">
                        <label htmlFor="salaryFrom">Salary</label>
                    </div>
                </div>
                <div className='row'>
				    <div className="col-6">
                        <div className="form-group">
                            <input type="text" name="salaryFrom" id="salaryFrom" placeholder="$ From" className="form-control" value={packageStart} onChange={onlyNumber1} required />
                        </div>
                    </div>
                    {/* <h2 className='text-center' style={{color:'black'}}>-</h2> */}
				    <div className="col-6">
                        <div className="form-group">
                            <input type="text" name="salaryTo" id="salaryTo" placeholder="$ To" className="form-control" value={packageEnd} onChange={onlyNumber2} required />
                        </div>
                    </div>
                    <div className="col">
                        <div className="form-group">
                            <select className='form-control' name='salaryAmount' id='salaryAmount' value={payRateDurationType} onChange={(e) => setPayRateDurationType(e.target.value)}>
                                <option disabled value="default">Per amount</option>
                                <option value="Hour">Hour</option>
                                <option value="Day">Day</option>
                                <option value="Week">Week</option>
                                <option value="Fortnight">Fortnight</option>
                                <option value="Month">Month</option>
                                <option value="Quarter">Quarter</option>
                                <option value="Year">Year</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="aboutCompany">About Company</label>
                            <textarea rows="2" name="aboutCompany" id="aboutCompany" placeholder="About Company" className="form-control pt-3 pb-3" value={about} onChange={(e) => setAbout(e.target.value)} required>
                            </textarea>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="jobDescription">Job Description</label>
                            <textarea rows="2" name="jobDescription" id="jobDescription" placeholder="Job Description" className="form-control pt-3 pb-3" value={description} onChange={(e) => setDescription(e.target.value)} required>
                            </textarea>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label htmlFor="requirements">Requirements</label>
                            <textarea rows="2" name="requirements" id="requirements" placeholder="Requirements" className="form-control pt-3 pb-3" value={requirements} onChange={(e) => setRequirements(e.target.value)} required>
                            </textarea>
                        </div>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-4'>
                        
                        <Link className="btn editBtn mt-0" to="/Profile"><span className="material-symbols-rounded">arrow_back</span></Link>
                        
                    </div>
                    <div className='col-md-4'>
                        <button type="submit" id="next" className="btn btn-primary btn-block nextBtn" onClick={() =>{
                            if(edit === 1){
                                editJobPost()
                            }else{
                                PostJob()
                            }
                        }}>
                        {loading && (
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> 
                        ) }
                        {edit === 1 ? " Update" : " Next"}</button>
                        <ToastContainer />
                    </div>
                    <div className='col-md-4'></div>
                </div>
            </div>
        </div>
    </div>
    </>

  )
}

export default JobPost;
