import React, { useCallback, useEffect, useState } from 'react'
// import companyImg from '../assets/images/company_img.png';
// import logo from '../assets/images/hired-logo.PNG'
import { Link, useNavigate } from 'react-router-dom';
import { fetchDataPrivate } from '../config/Reausable';
import Navbar from '../components/Navbar';


function Profile() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
    const navigate = useNavigate();
    const [companyLogo, setCompanyLogo] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [staffCount, setStaffCount] = useState("");
    const [foundedYear, setFoundedYear] = useState("");
    const [website, setWebsite] = useState("");
    const [companyBio, setCompanyBio] = useState("");
    const [companyHighlights, setCompanyHighlights] = useState("");
    const [coverImage, setCoverImage] = useState([]);
    const [jobList, setJobList] = useState([]);
    const [isEditProfileVisible, setIsEditProfileVisible] = useState(false);

    const handleEditProfileClick = () => {
        setIsEditProfileVisible(!isEditProfileVisible);
    };

    const redirectJobPost = () =>{
        navigate('../JobPost');
    }

    var userId = window.localStorage.getItem('userId');
    const viewProfile = useCallback(async () => {

        let data = {
            "companyId": userId,
        };

        var response = await fetchDataPrivate("companyPublicProfile",data);

        if(response.status === 200){
            const lastIndex = response.data[0].coverImage.length - 1;
            setCoverImage(response.data[0].coverImage[lastIndex].name);
            // setCoverImage(response.data[0].coverImage[0].name);
            setCompanyLogo(response.data[0].companyLogo);
            setCompanyName(response.data[0].companyName);
            setCompanyAddress(response.data[0].companyHeadquatersAddress);
            setStaffCount(response.data[0].staffCount);
            setFoundedYear(response.data[0].foundedYear);
            setWebsite(response.data[0].website);
            setJobList(response.data[0].jobList);
            setCompanyBio(response.data[0].companyBio);
            setCompanyHighlights(response.data[0].companyHighlights);

        }   
        else{
            console.log(response);
        }
    }, [userId]);

    const deleteJob = async (jobId) => {
        

        let deleteJob = {
            
            "jobId": jobId,
            
          };
        var response = await fetchDataPrivate("deleteJobPost",deleteJob);

        if(response.status === 200){
            window.location.reload();
        }
        else {
            console.error('Error fetching Job:', response);
        }

    };

    useEffect(() => {
        if (userId !== null && userId !== "" && userId !== undefined) {
            viewProfile();
        } else {
        navigate('/');
        }
    }, [userId, viewProfile, navigate]);

  return (
    <>
    <div className='container'>
        <div className="form-wrap mt-5 p-4">
        <Navbar />
            <div className='row profile_banner_row mt-1'>
                {/* <div className='col-md-1'></div> */}
                <div className='col-md-12 col-12 text-center'>
                    <div className="profile_banner_img_container">
                        <img src={coverImage} className="profile_banner_img" alt='company banner'/>
                    </div>
                </div>
                {/* <div className='col-md-1'></div> */}
            </div>
            <div className=''>
                <div className='row my-4'>
                    {/* <div className='col-md-1'></div> */}
                    <div className='col-md-10 col-8'>
                        <h4>{companyName}</h4>
                        <p>{companyHighlights}</p>
                        <p><span className="material-symbols-rounded location_icon">location_on</span>
                        {companyAddress}
                        </p>
                    </div>
                    <div className='col-md-2 col-4'>
                        <img src={companyLogo} className="profile_img" alt='company_logo'/> 
                    </div>
                </div>
                <div className='row bg-white py-4 key_container'>
                    <div className="col-12 d-flex flex-wrap">
                        <h6>Key Info</h6>
                    </div>
                    <div className='col-lg-4 my-2 my-lg-0'>
                        <div className='info_container py-4 text-center'>
                            <p className="info_text">{staffCount} staff</p>
                        </div>
                    </div>
                    <div className='col-lg-4 my-2 my-lg-0'>
                        <div className='info_container py-4 text-center'>
                            <p className="info_text">Founded {foundedYear}</p>
                        </div>
                    </div>
                    <div className='col-lg-4 my-2 my-lg-0'>
                        <div className='info_container py-4 text-center'>
                            <p className="info_text"><Link className='web_link' to={website} target="_blank">website</Link></p>
                        </div>
                    </div>
                </div>
                <div className='row my-4'>
                    <div className='col-12'>
                        <Link className='profile_link' to="">About</Link>
                        <div className="info_text">{companyBio}</div>
                    </div>
                </div>
                {/* <div className='row my-4'>
                    <div className='col-12'>
                        <Link className='profile_link' to="">Hignlights</Link>
                        <div className="info_text">{companyHighlights}</div>
                    </div>
                </div> */}
                <div className='row my-4'>
                    <div className='col-12'>
                        <div className='profile_link'>Open Jobs</div>
                    </div>
                </div>
                {jobList.map((job, index) => (
                    <div className='row bg-white py-3 post_container my-3' key={index}>
                        <div className='col-md-2 col-4'>
                            <img src={companyLogo} className="profile_img" alt='Job post img' />
                        </div>
                        <div className='col-md-8 col-5 d-flex flex-column justify-content-center'>
                            <Link className='job_link' to='../ViewJob' onClick={() => window.sessionStorage.setItem('jobId',job.jobId)}>
                                <h5>{job.jobtitle}</h5>
                                <p className='job_text my-1'>{job.firstName}</p>
                                <p className='job_text my-1'>${job.packageStart} - {job.packageEnd} / {job.payRateDurationType}</p>
                            </Link>
                            {/* <input type="text" value={job.jobId} /> */}
                        </div>
                        <div className='col-md-2 col-3 d-flex flex-column justify-content-between text-center'>
                            <span>{job.created}</span>
                            <div className='d-flex justify-content-center'>
                                {/* <span className="material-symbols-rounded mx-1">
                                    send
                                </span> */}
                                <button className="btn material-symbols-rounded mx-1" onClick={() => deleteJob(job.jobId)}>
                                    delete
                                </button>
                            </div>
                        </div>
                    </div>
                ))}

                {/* <div className='row my-4'>
                    <div className='col-12'>
                        <Link className='profile_link' to="">Posts</Link>
                    </div>
                </div> */}
                <div className='row my-4'>
                    <div className='col-md-7'></div>
                    <div className='col-md-3 col-8'>
                        <button type="submit" id="jobPost" className="btn btn-primary btn-block nextBtn" onClick={redirectJobPost}>Job Post</button>
                    </div>
                    <div className='col-md-2 col-4'>
                        <button type="submit" id="editProfile" className="btn editBtn" onClick={handleEditProfileClick}><span className="material-symbols-rounded">edit</span></button>
                        {isEditProfileVisible && (
                        <div className="editProfile-container d-flex flex-column align-items-center justify-content-around">
                            <Link to="../PersonalInfo" state={{edit : 1}}>Personal Info</Link>
                            <Link to="../CompanyProfile" state={{edit : 1}}>Change Profile Pic</Link>
                            <Link to="../CompanyInfo" state={{edit : 1}}>Company Info</Link>
                            <Link to="../CompanyProfile" state={{edit : 1}}>Company Media Info</Link>
                        </div>
                        )}
                    </div>
                </div>

            </div>
        </div>
    </div>
    </> 

  )
}

export default Profile