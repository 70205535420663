import React, { useCallback, useEffect, useState } from 'react';
import companyImg from '../assets/images/company_img.png';
import { Link, useNavigate } from 'react-router-dom';
import { fetchDataPrivate } from '../config/Reausable';
import Navbar from '../components/Navbar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark as solidBookmark } from '@fortawesome/free-solid-svg-icons';
// import { faBookmark as regularBookmark } from '@fortawesome/free-regular-svg-icons';



function ContactApplicant() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);

    const navigate = useNavigate();
    const userId = window.localStorage.getItem('userId'); // get user id from local storage
    // start states here
    const [userEmail, setUserEmail] = useState("");
    const [userFullName, setUserFullName] = useState("");
    const [applicants, setApplicants] = useState([]);
    const [email, setEmail] = useState([]);

    const subject = 'Your application to the job title role';

    const body = `Thank you for your application for this role. We have reviewed it and shortlisted you as one of our top candidates. We wish to have a further discussion regarding the position. Please suggest a couple of days/times that we can connect.
        
Best Regards,
${userFullName}
${userEmail}`;

    const mailtoLink = `mailto:${email}?bcc=${email}&subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    // sades applicant list api call
    const SavedApplicant = useCallback(async () =>{
        let data = {
            "userId": userId
        }
        const response = await fetchDataPrivate('savedApplicantList', data);

        if(response.status === 200){
            setApplicants(response.data.list);
            setUserFullName(response.data.userData.fullName);
            setUserEmail(response.data.userData.email);
            const emails = response.data.list.map((items) => items.email);
            setEmail(emails.join(","));
            
        } else{
            console.log(response);
        }

    },[userId]);
    
    // remove job applicant api call here
    const removeJobApplicant = async(val) =>{
        
        let data = {
            "jobapplicationId": val.jobapplicationId,
            "userId": val.userId
        }
        const response = await fetchDataPrivate('saveJobApplicants', data);
        if(response.status === 200){
            
            SavedApplicant();
            // setJobApplicantsBtn(false);
        }
        else{
            console.log(response);
        }
    }

    useEffect(() => {
        if (userId !== null && userId !== "" && userId !== undefined) {
            SavedApplicant();
        } else {
        navigate('/');
        }
    }, [userId, SavedApplicant, navigate]);
  return (
    <>
    <div className='container'>
        <div className="form-wrap mt-5 p-4">	
            <Navbar />
            <div className='row'>
                <div className='col-md-1'></div>
                <div className='col-md-10 p-5'>
                    <img src={companyImg} className="banner-img" alt='company banner' style={{width:"100%"}} />
                </div>
                <div className='col-md-1'></div>
            </div>
            <div className='row'>
                <div className='col text-center'>
                    <p>Now that you’ve shortlisted the candidates, let’s send an 
                    email to let them know you have interest, and to 
                    schedule a further discussion!</p>
                </div>
            </div>
            {applicants.map((list,index)=>(
                    <div className='row bg-white py-3 post_container my-3' key={index}>
                        
                        <div className='col-md-2 col-5'>
                            <img src={list.cacheImage} className="profile_img w-100 h-auto" alt='Job post img' />
                        </div>
                        <div className='col-md-8 col-5 d-flex flex-column justify-content-center'>
                                <h6>{list.fullName}</h6>
                                <p className='job_text my-1'>{list.bio}</p>
                                <p className='job_text my-1'>${list.minimumPayRate} - ${list.maximumPayRate} / {list.payRateDurationType}</p>
                        </div>
                        <div className='col-md-2 col-2 d-flex flex-column justify-content-between text-center'>
                        
                            <button className='btn saveBtn shadow-none' onClick={() => removeJobApplicant(list)}><FontAwesomeIcon icon={solidBookmark} /></button>  
                        
                            <span className='job_text'>{list.created}</span>
                        </div>
                    </div>
                ))}
                <div className="row mt-2">
                    <div className="col-12 d-flex justify-content-lg-end justify-content-center">
                        <Link className="btn editBtn" to="/ViewApplicants"><span className="material-symbols-rounded">arrow_back</span></Link>
                        <Link className="btn btn-primary nextBtn mx-lg-4 mx-1" to={mailtoLink} >Email Applicants</Link>
                    </div>
                </div>
        </div>
    </div>
    </>
  )
}

export default ContactApplicant
