import React, { useState, useEffect } from 'react';
import companyImg from '../assets/images/company_img.png';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { fetchData, fetchDataPrivate } from '../config/Reausable';
import AutoComplete from '../components/AddressAutoComplete';
import { useCallback } from 'react';

function CompanyInfo() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
    const navigate = useNavigate();
    const location = useLocation();
    const edit = location.state ? location.state.edit : null;

    const [address, setAddress] = useState("");
    const [companyName,setCompanyName] = useState("");
    const [industryId, setIndustryId] = useState("default");
    const [staffCount, setStaffCount] = useState("default");
    const [companyFoundedYear, setCompanyFoundedYear] = useState("");
    const [highlights, setHighlights] = useState("");
    const [bio, setBio] = useState("");
    const [industry, setIndustry] = useState([]);
    const fetchIndustry = async () =>{
        
        var response = await fetchData("industriesList",JSON);

        if(response.status === 200){
            setIndustry(response.data);
        }
        else {
            console.error('Error fetching Industry:', response);
        }
    }

    useEffect(() => {
        fetchIndustry();
    }, []);

    var userId = window.localStorage.getItem('userId');
    const submitCompanyInfo = async() =>{
        let data = {
            "companyName": companyName,
            "userId": userId,
            "companyAddress": address,
            "industriId": industryId,
            "staffCount": staffCount,
            "foundedYear": companyFoundedYear,
            "companyHighlights": highlights,
            "companyBio": bio
        };
        var response = await fetchDataPrivate("addCompanyDetails",data);
        if(response.status === 200){
            
            navigate('../CompanyProfile');
        }
        else{
            console.log(response);
        }

    }

    const fetchCompanyInfo = useCallback(async () => {
        let data2 = {
            "userId": userId
          };
        var response = await fetchDataPrivate("myProfile",data2);

        if(response.status === 200){
            setCompanyName(response.data[0].companyName);
            setAddress(response.data[0].companyHeadquatersAddress);
            setIndustryId(response.data[0].industry[0].industriId);
            setStaffCount(response.data[0].staffCount);
            setCompanyFoundedYear(response.data[0].foundedYear);
            setHighlights(response.data[0].companyHighlights);
            setBio(response.data[0].companyBio);
        }
        else{
            console.log(response);
        }
    },[userId]);

    useEffect(() => {
        if(edit === 1){
            fetchCompanyInfo();
        }
    }, [edit,fetchCompanyInfo]);
    
  return (
    <div className='container'>

        <div className="form-wrap mt-5">	
            <div className='company-form'>
                <div className='row'>
                    <div className='col-md-1'></div>
                    <div className='col-md-10 p-5'>
                        <img src={companyImg} className="banner-img" alt='company banner' style={{width:"100%"}} />
                    </div>
                    <div className='col-md-1'></div>
                </div>
                <div className='row'>
                    <div className='col text-center'>
                        <h3>Please enter your company info</h3>
                    </div>
                </div>
                <div className="row mt-4">
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="companyName">Company Name</label>
                            <input type="text" name="companyName" id="companyName" placeholder="Company Name" className="form-control" value={companyName} onChange={(e) => setCompanyName(e.target.value)} required />
                        </div>
                    </div>
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="companyHeadquartersAddress">Company headquarters address</label>
                            {/* <input type="text" name="companyHeadquartersAddress" id="companyHeadquartersAddress" placeholder="Company headquarters address" className="form-control" required /> */}
                            <AutoComplete name="companyHeadquartersAddress" id="companyHeadquartersAddress" placeholder="Company headquarters address" value={address} onAddressSelect={(value) => setAddress(value)}/>
                        </div>
                    </div>
				    
                </div>
                <div className="row">
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="industry">Industry</label>
                            <select className='form-control' name='industry' id='industry' value={industryId} onChange={(e) => setIndustryId(e.target.value)}>
                                <option disabled value="default">Select your industry</option>
                                {industry.map((ind,index) =>(
                                    <option key={index} value={ind.industriId}>{ind.name}</option>
                                ))}
                            </select>
                        </div>
                    </div>
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="staffCount">Staff Count</label>
                            <select className='form-control' name='staffCount' id='staffCount' value={staffCount} onChange={(e) => setStaffCount(e.target.value)}>
                                <option disabled value="default">Select your staff count</option>
                                <option value="1-9">1 - 9</option>
                                <option value="10-24">10 - 24</option>
                                <option value="25-99">25 - 99</option>
                                <option value="100-249">100 - 249</option>
                                <option value="250-999">250 - 999</option>
                                <option value="1000+">1000+</option>
                                <option value="10,000+">10,000+</option>
                                <option value="50,000+">50,000+</option>  
                            </select>
                        </div>
                    </div>
                </div>
                <div className='row'>
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="companyFoundedYear">Company founded year</label>
                            <input type="month" name="companyFoundedYear" id="companyFoundedYear" placeholder="Company founded year" className="form-control" value={companyFoundedYear} onChange={(e) => setCompanyFoundedYear(e.target.value)} required />
                        </div>
                    </div>
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="highlights">Highlights</label>
                            <input type="text" name="highlights" id="highlights" placeholder="Company Highlights(150 characters max)" className="form-control" value={highlights} onChange={(e) => setHighlights(e.target.value)} required />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="bio">Bio</label>
                            <textarea rows="3" name="bio" id="bio" placeholder="Company Bio" className="form-control pt-3 pb-3" value={bio} onChange={(e) => setBio(e.target.value)}required>
                            </textarea>
                        </div>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-4'>
                        { 
                        edit === 1 ? 
                            <Link className="btn editBtn mt-0" to="/Profile"><span className="material-symbols-rounded">arrow_back</span></Link>
                        : null 
                        }
                    </div>
                    <div className='col-md-4'>
                        <button type="submit" id="next" className="btn btn-primary btn-block nextBtn" onClick={submitCompanyInfo}>Next</button>
                    </div>
                    <div className='col-md-4'></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CompanyInfo;
