import React, { useCallback, useEffect, useRef, useState } from 'react';
import companyImg from '../assets/images/company_img.png';
import { Link, useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { fetchDataPrivate, fetchFormData } from '../config/Reausable';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

function CompanyProfile() {
    useEffect(() => {
        window.scrollTo(0, 0); // Scroll to the top of the page
      }, []);
    const navigate = useNavigate();
    const location = useLocation();
    const edit = location.state ? location.state.edit : null;
    const [logo, setLogo] = useState(null);
    const [logoFile, setLogoFile] = useState(null);
    const [cover, setCover] = useState([]);
    const [displayCover, setDisplayCover] = useState([]);
    const [websiteUrl, setWebsiteUrl] = useState("");
    const [phone, setPhone] = useState("");
    const [facebookUrl, setFacebookUrl] = useState("");
    const [instagramUrl, setInstagramUrl] = useState("");
    const [twitterUrl, setTwitterUrl] = useState("");
    const [linkedinUrl, setLinkedinUrl] = useState("");
    const inputRef = useRef(null);

    const handleLogoChange = async (e) => {
        
        const selectedFile = e.target.files[0];
        if (selectedFile) {
        // Create a URL for the selected image
        const imageUrl = URL.createObjectURL(selectedFile);
        setLogo(imageUrl);
        setLogoFile(selectedFile);
    }
    
};
    const handleImageClick = () => {
        // Trigger a click event on the hidden file input to allow reselecting the image
        inputRef.current.click();
    };

    const fileInputRef = useRef(null);
    const handleUploadBoxClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleLabelClick = (e) => {
        // Prevent the click event from bubbling up to the parent upload-box
        e.stopPropagation();
    };

    const MAX_LENGTH = 5;
    const handleImageChange = (e) => {
        const selectedFiles = Array.from(e.target.files);
        const filePaths = selectedFiles.map((file) => file); // Store the selected file names as paths
        if (filePaths.length > MAX_LENGTH) {
            console.log(`Cannot upload more than ${MAX_LENGTH} images.`);
        } else {
            // console.log("Selected Files:", filePaths);
            setCover(filePaths); // Store the array of image paths
        }
    };
    
    const handleRemove1 = async (index1) => {
        

        let tmparrimg = displayCover.filter((item,index) => index != index1)

        setDisplayCover(tmparrimg);

        let deleteImage = {
            
            "imageId":displayCover[index1].imageId
          };
        var response = await fetchDataPrivate("deleteImage",deleteImage);

        if(response.status === 200){
            setDisplayCover(tmparrimg);
            console.log(response);
        }
        else {
            console.error('Error fetching Image:', response);
        }

    };
    const handleRemove2 = async (index1) => {
        

        let tmparrimg = cover.filter((item,index) => index != index1)
        setCover(tmparrimg);

    };
    const submitCompanyProfile = async () =>{

        let param = new FormData()
        param.append('userId',window.localStorage.getItem('userId'));
        param.append('companyNumber',phone);
        param.append('website',websiteUrl);
        param.append('facebookLink',facebookUrl);
        param.append('instagramLink',instagramUrl);
        param.append('twitterLink',twitterUrl);
        param.append('linkedInLink',linkedinUrl);
        if (logoFile) {
            param.append('logo', logoFile); // Append the File object
        }

        cover.forEach((imagePath) => {
            param.append('cover', imagePath);
        });        

        var response = await fetchFormData("companyMultiMedia",param);
        if(response.status === 200){
            
            navigate('../Profile');
            console.log(response);
        }
        else{
            console.log(response);
        }
    }

    var userId = window.localStorage.getItem('userId');

    const fetchCompanyProfile = useCallback(async() =>{
        let data2 = {
            "userId": userId
          };
        var response = await fetchDataPrivate("myProfile",data2);

        if(response.status === 200){
            setLogo(response.data[0].companyLogo);
            setLogoFile(response.data[0].companyLogo);
            setDisplayCover(response.data[0].coverImage);
            setWebsiteUrl(response.data[0].website);
            setPhone(response.data[0].companyNumber);
            setFacebookUrl(response.data[0].facebookLink);
            setInstagramUrl(response.data[0].instagramLink);
            setTwitterUrl(response.data[0].twitterLink);
            setLinkedinUrl(response.data[0].linkedInLink);
        }
        else{
            console.log(response);
        }
    },[userId]);

    useEffect(() => {
        if(edit === 1){

            fetchCompanyProfile();  
        }
    }, [edit,fetchCompanyProfile]);
  return (
    <div className='container'>

        <div className="form-wrap mt-5">	
            <div className='company-form'>
                <div className='row'>
                    <div className='col-md-1'></div>
                    <div className='col-md-10 p-5'>
                        <img src={companyImg} className="banner-img" alt='company banner' style={{width:"100%"}} />
                    </div>
                    <div className='col-md-1'></div>
                </div>
                <div className='row'>
                    <div className='col text-center'>
                        <h3>Upload Company Logo <span>(optional)</span></h3>
                    </div>
                </div>
                <div className='row'>
                    <div className='col d-flex justify-content-center'>
                        <div className="circle-upload">
                            <input
                                type="file"
                                id="uploadLogo"
                                name='uploadLogo'
                                className="upload-logo"
                                accept="image/*"
                                onChange={handleLogoChange}
                                ref={inputRef}
                                style={{ display: 'none' }} // Hide the file input
                            />
                            {!logo ? (
                                <label htmlFor="uploadLogo">
                                <span className="material-symbols-rounded camera">photo_camera</span>
                                </label>
                            ) : (
                                <div
                                className="cover-background"
                                style={{ backgroundImage: `url(${logo})`, backgroundSize: 'cover', backgroundPosition: 'center', width: '100px', height: '100px',cursor: 'pointer' }}
                                onClick={handleImageClick} // Allow reselecting image by clicking on it
                                ></div>
                            )}
                        </div>
                    </div>
                </div>
                
                <div className="row mt-4">
                    <label htmlFor="coverPhotos">Upload Cover Photos</label>
                </div>
                <div className="row">
                    <div className='col'>
                        <div className='image-container'>
                            <input
                            type="file"
                            id="coverPhotos"
                            className="upload-image"
                            accept="image/*"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            onChange={handleImageChange}
                            multiple 
                            />
                            <label className="upload-preview">
                                <div className="upload-box" onClick={handleUploadBoxClick}>
                                    <label className="upload-box-label" htmlFor="coverPhotos" onClick={handleLabelClick}>+</label>
                                    <span className="upload-box-text text-center">Cover Photos</span>
                                </div>
                            </label>
                        </div>   
                    </div>
                </div>
                <div className="row">
                { displayCover ? (
                    displayCover.map((imageList, index) => (
                        <div className="col-lg-3 col-6" key={index}>
                            <div className="mt-3" style={{ position: "relative", maxWidth: "fit-content" }}>
                            
                            <img src={imageList.name} alt={`Media ${index}`} width="100%" />
                            
                            </div>
                            <button
                            className="btn btn-danger p-1 px-2 rounded-circle"
                            style={{
                                position: "absolute",
                                right: "5%",
                                top: "5%",
                                fontSize: '12px',
                                }}
                            onClick={() => handleRemove1(index)}
                            >
                            <FontAwesomeIcon icon={faXmark} />
                            </button>
                        </div>
                        ))
                ):""}
                { cover ? (
                    cover.map((imageList, index) => (
                        <div className="col-lg-3 col-6" key={index}>
                            <div className="mt-3" style={{ position: "relative", maxWidth: "fit-content" }}>
                            {imageList.type && imageList.type.startsWith("video/") ? (
                                <video autoPlay muted loop>
                                <source src={URL.createObjectURL(imageList)} type={imageList.type} />
                                Your browser does not support the video tag.
                                </video>
                            ) : (
                                <img src={URL.createObjectURL(imageList)} alt={`Media ${index}`} width="100%" />
                            )}
                            </div>
                            <button
                            className="btn btn-danger p-1 px-2 rounded-circle"
                            style={{
                                position: "absolute",
                                right: "5%",
                                top: "5%",
                                fontSize: '12px',
                              }}
                            onClick={() => handleRemove2(index)}
                            >
                            <FontAwesomeIcon icon={faXmark} />
                            </button>
                        </div>
                        ))
                ) :""}
                </div>
                <div className="row mt-4">
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="website">Website</label>
                            <input type="text" name="website" id="website" placeholder="Company Website" className="form-control" value={websiteUrl} onChange={(e) => setWebsiteUrl(e.target.value)} required />
                        </div>
                    </div>
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="phone">Phone Number</label>
                            <input type="text" name="phone" id="phone" placeholder="Company Phone Number" className="form-control" value={phone} onChange={(e) => setPhone(e.target.value)} required />
                        </div>
                    </div>
                </div>
                <div className="row">
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="facebook">Facebook</label>
                            <input type="text" name="facebook" id="facebook" placeholder="Facebook url" className="form-control" value={facebookUrl} onChange={(e) => setFacebookUrl(e.target.value)} required />
                        </div>
                    </div>
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="instagram">Instagram</label>
                            <input type="text" name="instagram" id="instagram" placeholder="Instagram url" className="form-control" value={instagramUrl} onChange={(e) => setInstagramUrl(e.target.value)} required />
                        </div>
                    </div>
                </div>
                <div className='row'>
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="twitter">Twitter</label>
                            <input type="text" name="twitter" id="twitter" placeholder="Twitter url" className="form-control" value={twitterUrl} onChange={(e) => setTwitterUrl(e.target.value)} required />
                        </div>
                    </div>
				    <div className="col-md-6">
                        <div className="form-group">
                            <label htmlFor="linkedIn">LinkedIn</label>
                            <input type="text" name="linkedIn" id="linkedIn" placeholder="LinkedIn url" className="form-control" value={linkedinUrl} onChange={(e) => setLinkedinUrl(e.target.value)} required />
                        </div>
                    </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-4'>
                        { 
                        edit === 1 ? 
                            <Link className="btn editBtn mt-0" to="/Profile"><span className="material-symbols-rounded">arrow_back</span></Link>
                        : null 
                        }
                    </div>
                    <div className='col-md-4'>
                        <button type="submit" id="next" className="btn btn-primary btn-block nextBtn" onClick={submitCompanyProfile}>Done</button>
                    </div>
                    <div className='col-md-4'></div>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CompanyProfile;
